:root {
    --primary: #f29400;
    --secondary: #414141;
    --light: #f9f9f9;
    --text-color: #414141;
    --radius: 20px;
    --small-radius: 10px;
    --box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.05);
    --inner-shadow: inset 0px 5px 10px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 600px) {
    /* General */
    h1 {
        font-size: 60px;
    }
    h2 {
        font-size: 24px;
        line-height: 29px;
    }
    h3 {
        font-size: 18px;
        line-height: 22px;
    }
    p {
        font-size: 14px;
        line-height: 17px;
    }
    img {
        width: 100%;
    }

    /* Header */
    .Header {
        flex-direction: column-reverse;
    }

    /* Section Card */
    .top-card {
        flex-direction: column;
    }
    .top-card > .tandem-card {
        margin-top: -80px;
    }
    .section-illustation {
        margin-top: -20px;
    }
    .bottom-card {
        flex-direction: column;
    }

    /* Tandem Card */
    .tandem-card-content {
        margin-top: calc(-60px - 3rem);
        padding-top: calc(60px + 4rem);
    }
    .tandem-card-content.two-row {
        margin-top: calc(-60px - 5rem);
        padding-top: calc(60px + 6rem);
    }

    /* Item List */
    .item {
        display: flex;
        flex-direction: column;
        justify-items: center;
        padding: 20px;
        flex-grow: 1;
        flex-basis: 0;
    }
    .item-icon > img {
        width: 50px;
    }
    .item-title {
        text-transform: uppercase;
    }
    .item-text {
        text-align: left;
    }

    /* Features */
    .features-items {
        flex-direction: column;
    }

    /* Comparative Card */
    .comparative {
        flex-direction: column;
    }

    /* Nav */
    .nav-item-container {
        flex-direction: column;
    }
    .nav-item-container.closed {
        height: 40px;
        overflow: hidden;
    }
    .closed > a > .nav-item {
        padding: 2px;
        margin: 2px;
        transition: 0.25s ease-in-out;
        border-radius: 100px;
        background-color: var(--secondary);
        color: var(--secondary);
        font-size: 0px;
        width: 30px;
    }
    .nav-item:hover {
        box-shadow: unset;
    }
    .menu-trigger.closed {
        width: 35px;
        height: 30px;
        margin-bottom: -30px;
    }
    .menu-trigger.open {
        width: 35px;
        height: 40px;
        margin: auto;
    }
    .menu-trigger > img {
        display: block;
    }
    .menu-trigger.closed > img {
        opacity: 0;
        transition: 0.2s ease-in-out;
    }
    .menu-trigger.open > img {
        opacity: 1;
        transition: 0.2s ease-in-out;
    }

    /* Simple Features */
    .simple-features {
        flex-direction: column;
    }
    .simple-features-container {
        margin-top: -20px;
        margin-left: unset;
        flex-direction: column;
    }
    .simple-feature {
        flex-basis: 100%;
    }

    /* Contact Form */
    .contact-container {
        border-radius: var(--radius);
        border: 1px solid var(--primary);
        padding: 40px;
    }
    .contact-form {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        flex-shrink: 1;
    }
    textarea,
    input {
        border: none;
        box-shadow: var(--box-shadow);
        border-radius: var(--small-radius);
        padding: 10px 15px;
        margin-bottom: 10px;
    }
    .col-50 {
        flex-basis: 50%;
    }
    .col-100 {
        flex-basis: 100%;
    }

    /* Call To Action */
    .call-to-action {
        flex-direction: column;
        padding: unset;
        padding: 20px;
    }

    /* Map */
    .location-container {
        flex-direction: column;
    }
    .location {
        flex-basis: 50%;
        text-align: left;
    }
    .map-container {
        flex-basis: 50%;
    }
}
