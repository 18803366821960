:root {
    --primary: #f29400;
    --secondary: #414141;
    --light: #f9f9f9;
    --text-color: #414141;
    --radius: 20px;
    --small-radius: 10px;
    --box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.05);
    --inner-shadow: inset 0px 5px 10px rgba(0, 0, 0, 0.15);
}

/* General */
a {
    text-decoration: none;
    color: unset;
}
h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}
h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}
p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--secondary);
}
img {
    width: 100%;
}
li::marker {
    color: var(--primary);
}
button {
    background: unset;
    border: unset;
}
.primary {
    color: var(--primary);
}
.white {
    color: white;
}
body {
    font-family: "Montserrat";
    color: var(--text-color);
    text-align: center;
    background-color: rgb(245, 245, 245);
}

/* Header */
.Header {
    display: flex;
    align-items: center;
    padding: 0 5%;
    max-width: 1000px;
    margin: 100px auto 0;
}
.header-text {
    flex-basis: 50%;
    flex-shrink: 0;
    text-align: left;
}
.header-image {
    flex-basis: 50%;
    flex-shrink: 0;
}
.header-text > img {
    width: 50%;
}

/* Content */
.Content {
    padding: 5%;
    max-width: 1000px;
    margin: auto;
}

/* Section Card */
.section-card {
    display: flex;
    flex-direction: column;
    margin: 100px 0;
}
.top-card {
    background-color: var(--light);
    display: flex;
    border-radius: var(--radius);
    box-shadow: var(--box-shadow);
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}
.top-card > .tandem-card {
    flex-basis: 45%;
    margin-top: -80px;
}
.section-illustation {
    flex-basis: 45%;
    margin-top: -80px;
}
.bottom-card {
    display: flex;
    padding: 20px;
    border-radius: var(--radius);
    box-shadow: var(--box-shadow);
    background-color: white;
}

/* Tandem Card */
.tandem-card-title {
    background-color: var(--primary);
    margin: unset;
    color: white;
    text-align: left;
    display: flex;
    padding: 40px;
    box-shadow: var(--inner-shadow);
    border-radius: var(--radius);
}
.tandem-card-title.dark {
    background-color: var(--secondary);
}
.tandem-card-content {
    border: 1px solid var(--secondary);
    border-radius: var(--radius);
    text-align: left;
    margin-top: calc(-60px - 3rem);
    margin-left: 20px;
    margin-right: -20px;
    padding: 40px;
    padding-top: calc(60px + 4rem);
}
.tandem-card-content.two-row {
    margin-top: calc(-60px - 3rem);
    padding-top: calc(60px + 4rem);
}

/* Item List */
.item {
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding: 20px;
    flex-grow: 1;
    flex-basis: 0;
}
.item-icon > img {
    width: 50px;
}
.item-title {
    text-transform: uppercase;
}
.item-text {
    text-align: left;
}

/* Features */
.features-title {
    background-color: var(--primary);
    margin: unset;
    color: white;
    text-align: left;
    display: flex;
    padding: 40px;
    padding-bottom: 100px;
    box-shadow: var(--inner-shadow);
    border-radius: var(--radius);
}
.features-items {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
}
.feature {
    display: flex;
    background-color: white;
    padding: 20px;
    margin: 20px;
    margin-top: calc(-100px + 40px);
    border-radius: var(--radius);
    box-shadow: var(--box-shadow);
}

/* Comparative Card */
.comparative {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
}
.comparative-card {
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 40px;
    box-shadow: var(--inner-shadow);
    border-radius: var(--radius);
}
.comparative-card > * {
    color: white;
}
.comparative-card.primary {
    background-color: var(--primary);
}
.comparative-card.secondary {
    background-color: var(--secondary);
}

/* Button */
.button {
    background-color: white;
    box-shadow: var(--box-shadow);
    border-radius: var(--small-radius);
    font-weight: bold;
    padding: 10px 20px;
    transition: 0.15s ease-in-out;
}
.button:hover {
    cursor: pointer;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
}
.button:active {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
}
.button.primary {
    color: var(--primary);
}
.button.secondary {
    color: var(--secondary);
}
.button.disabled {
    pointer-events: none;
    background-color: #eeeeee;
    color: #aaaaaa;
    cursor: not-allowed;
}

/* Nav */
.Nav {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background-color: white;
    box-shadow: var(--box-shadow);
}
.nav-item-container {
    display: flex;
}
.nav-item {
    padding: 10px 20px;
    margin: 5px;
    transition: 0.25s ease-in-out;
    box-shadow: 0 0 0 inset rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    background-color: white;
    width: unset;
    font-size: inherit;
}
.nav-item:hover {
    box-shadow: var(--inner-shadow);
}
.nav-item.selected {
    background-color: var(--primary);
    box-shadow: var(--box-shadow);
    color: white;
    font-weight: bold;
}
.nav-item.platform {
    border: 1px solid var(--primary);
    box-shadow: var(--box-shadow);
    font-weight: bold;
}
.menu-trigger {
    width: 0;
    height: 0;
}
.menu-trigger > img {
    display: none;
}

.Footer {
    width: 100%;
    border-radius: var(--small-radius);
    background-color: var(--secondary);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media screen and (min-width: 769px) {
    .Footer {
        flex-direction: row;
    }
}
.footer-company-info {
    text-align: left;
    border-radius: var(--radius);
    border: 2px solid var(--primary);
    padding: 30px 40px;
    margin: 20px;
}
.footer-company-info > p {
    color: var(--light);
    line-height: 50%;
}
.footer-company-info > img {
    width: 200px;
}
.footer-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-items-main {
    line-height: 30px;
    text-align: start;
    padding: 20px;
}
.footer-items-legal {
    line-height: 30px;
    text-align: start;
    font-weight: bold;
    padding: 20px;
}

/* Simple Features */
.simple-features {
    display: flex;
    align-items: flex-start;
    margin-bottom: 100px;
}
.simple-features-left {
    flex-basis: 30%;
}
.simple-features-right {
    flex-basis: 70%;
}
.simple-features-container {
    background-color: white;
    box-shadow: var(--box-shadow);
    border-radius: var(--radius);
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    margin-left: 40px;
}
.simple-feature {
    flex-basis: 40%;
    padding: 0 20px;
    text-align: left;
}

/* Contact Form */
.contact-container {
    border-radius: var(--radius);
    border: 1px solid var(--primary);
    padding: 40px;
}
.contact-form {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 1;
}
textarea,
input {
    border: none;
    box-shadow: var(--box-shadow);
    border-radius: var(--small-radius);
    padding: 10px 15px;
    margin-bottom: 10px;
}
.col-50 {
    flex-basis: 50%;
}
.col-100 {
    flex-basis: 100%;
}
.legal-acknowledgement {
    color: white;
    font-size: 12px;
    text-align: start;
    border-radius: var(--small-radius);
    border: 1px solid var(--light);
    padding: 5px 10px;
    margin: 2px 2px 10px 2px;
    display: flex;
    cursor: pointer;
}
.legal-acknowledgement.light {
    color: var(--text-color);
    border: 1px solid #cccccc;
}
.legal-acknowledgement-toggle {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin: 5px 10px 5px 0px;
    border: 1px solid var(--light);
    border-radius: 5px;
}
.legal-acknowledgement-toggle.light {
    border: 1px solid #cccccc;
}
.legal-acknowledgement-toggle-element {
    flex-shrink: 0;
    width: 0;
    height: 0;
    margin: 14px;
    border-radius: 0;
    transition: 0.2s ease-in-out;
}
.legal-acknowledgement-toggle-element.active {
    width: 14px;
    height: 14px;
    margin: 3px;
    border-radius: 3px;
    background-color: var(--primary);
}

/* Call To Action */
.call-to-action {
    display: flex;
    background-color: var(--secondary);
    box-shadow: var(--inner-shadow);
    padding: 20px;
    padding-left: 40px;
    border-radius: var(--radius);
    text-align: left;
    align-items: center;
    margin-bottom: 100px;
}

/* Cookie banner */
.cookie-link {
    color: var(--primary);
    font-weight: bold;
}

/* Map */
.location-container {
    display: flex;
    background-color: white;
    box-shadow: var(--box-shadow);
    border-radius: var(--radius);
    padding: 40px;
}
.location {
    flex-basis: 50%;
    text-align: left;
}
.map-container {
    flex-basis: 50%;
}

/* Legal Menu */
.legal-menu-container {
    background-color: white;
    padding: 40px;
    margin: 40px;
    border-radius: var(--radius);
    border: 2px solid var(--primary);
}
.legal-menu-item {
    padding: 10px 20px;
    margin-top: 10px;
    border-radius: var(--small-radius);
    border: 2px solid var(--light);
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.05);
    transition: 0.5s;
}
.legal-menu-item.selected,
.legal-menu-item:hover {
    color: white;
    background-color: var(--primary);
    box-shadow: var(--box-shadow);
}

/* Legal */
.legal-content {
    text-align: left;
    font-size: 14px;
    line-height: 17px;
}
.legal-title {
    margin-top: 50px;
    text-align: left;
    color: var(--primary);
    justify-content: start;
}
.legal-separator {
    width: 100%;
    margin: 0 20px;
}
